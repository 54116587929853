var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cameras"},[_c('div',{staticClass:"cameras__header"},[_c('div',{staticClass:"header__title"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('annotationCameraTranslater.video')))]),_c('i',{staticClass:"el-icon-close text-2xl cursor-pointer text-darkblue font-semibold ml-3",on:{"click":_vm.closeAnnotation}})]),_c('p',{staticClass:"cameras-name"},[_vm._v(" "+_vm._s(_vm.$t('annotationCameraTranslater.object'))+" "),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.item.name))])]),_c('p',{staticClass:"cameras-name"},[_vm._v(" "+_vm._s(_vm.$t('annotationCameraTranslater.videoRegister'))+" "),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.deviceName))])])]),_c('div',{staticClass:"cameras__body"},[_c('div',{staticClass:"body__switch-button"},[_c('button',{class:[
          'switch-button',
          { 'switch-button--active': _vm.isActiveLayout === 'grid' }
        ],attrs:{"type":"button"},on:{"click":function($event){return _vm.switchLayout('grid')}}},[_vm._v(" "+_vm._s(_vm.$t('annotationCameraTranslater.grid'))+" ")]),_c('button',{class:[
          'switch-button',
          { 'switch-button--active': _vm.isActiveLayout === 'tape' }
        ],attrs:{"type":"button"},on:{"click":function($event){return _vm.switchLayout('tape')}}},[_vm._v(" "+_vm._s(_vm.$t('annotationCameraTranslater.ribbon'))+" ")])]),_c('div',{class:[
        'body__stream',
        { 'body__stream--tape': _vm.isActiveLayout === 'tape' }
      ]},_vm._l((_vm.streams),function(stream,index){return _c('div',{key:stream.stream,staticClass:"wrapper-video"},[_c('video',{class:['video', { 'video--tape': _vm.isActiveLayout === 'tape' }],attrs:{"id":'videoPlayer' + index,"width":"100%"},on:{"click":function($event){return _vm.openModal(stream)}}}),_c('button',{staticClass:"btn-show",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.$t('annotationCameraTranslater.see'))+" ")]),_c('div',{staticClass:"name-loader"},[_c('p',{staticClass:"name"},[_vm._v("Камера: "+_vm._s(stream.stream))]),_c('LoaderIcon',{staticClass:"loader",attrs:{"id":'loading-indicator' + index}})],1)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }