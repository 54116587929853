import { render, staticRenderFns } from "./annotationCamera.vue?vue&type=template&id=71893919&scoped=true"
import script from "./annotationCamera.vue?vue&type=script&lang=js"
export * from "./annotationCamera.vue?vue&type=script&lang=js"
import style0 from "./annotationCamera.vue?vue&type=style&index=0&id=71893919&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71893919",
  null
  
)

export default component.exports