<template>
  <div class="cameras">
    <div class="cameras__header">
      <div class="header__title">
        <p class="title">{{ $t('annotationCameraTranslater.video') }}</p>
        <i
          class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold ml-3"
          @click="closeAnnotation"
        ></i>
      </div>
      <p class="cameras-name">
        {{ $t('annotationCameraTranslater.object') }}
        <span class="name">{{ item.name }}</span>
      </p>
      <p class="cameras-name">
        {{ $t('annotationCameraTranslater.videoRegister') }}
        <span class="name">{{ deviceName }}</span>
      </p>
    </div>
    <div class="cameras__body">
      <div class="body__switch-button">
        <button
          type="button"
          :class="[
            'switch-button',
            { 'switch-button--active': isActiveLayout === 'grid' }
          ]"
          @click="switchLayout('grid')"
        >
          {{ $t('annotationCameraTranslater.grid') }}
        </button>
        <button
          type="button"
          :class="[
            'switch-button',
            { 'switch-button--active': isActiveLayout === 'tape' }
          ]"
          @click="switchLayout('tape')"
        >
          {{ $t('annotationCameraTranslater.ribbon') }}
        </button>
      </div>
      <div
        :class="[
          'body__stream',
          { 'body__stream--tape': isActiveLayout === 'tape' }
        ]"
      >
        <div
          v-for="(stream, index) in streams"
          :key="stream.stream"
          class="wrapper-video"
        >
          <video
            :class="['video', { 'video--tape': isActiveLayout === 'tape' }]"
            :id="'videoPlayer' + index"
            width="100%"
            @click="openModal(stream)"
          ></video>
          <!-- <img :id="`camera${index}-preview`" src="" alt="" /> -->
          <button type="button" class="btn-show">
            {{ $t('annotationCameraTranslater.see') }}
          </button>
          <div class="name-loader">
            <p class="name">Камера: {{ stream.stream }}</p>
            <LoaderIcon :id="'loading-indicator' + index" class="loader" />
          </div>
          <!-- <div
            v-if="isError"
            :class="[
              'video-error',
              { 'video-error--tape': isActiveLayout === 'tape' }
            ]"
          >
            <VideoOffIcon class="icon" />
            <p class="text">Ошибка</p>
          </div> -->
        </div>
        <!-- <video ref="video" width="100%" height="640" controls></video> -->
      </div>
    </div>
  </div>
</template>

<script>
import { videosApi } from '@/api'
import LoaderIcon from '../assets/LoaderIcon'
// import VideoOffIcon from '../assets/VideoOffIcon'
import Hls from 'hls.js'

export default {
  name: 'AnnotationCamera',
  components: {
    LoaderIcon
    // VideoOffIcon,
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isActiveLayout: 'grid',
      streams: [],
      isError: false,
      hls1: null,
      hlsArr: []
    }
  },

  computed: {
    deviceName() {
      return this.item.custom_fields.find(
        (el) => el.key === 'video_device_name'
      ).value
    }
  },

  methods: {
    closeAnnotation() {
      this.$emit('close-annotation-camera', false)
    },

    switchLayout(layout) {
      this.isActiveLayout = layout
    },

    addIceCandidate(connection, event) {
      connection
        .addIceCandidate(event.candidate)
        .then(this.addIceCandicateSuccess)
        .catch(this.addIceCandicateFailure)
    },

    addIceCandicateSuccess() {
      console.log('Ice candidate added successfully')
    },

    openModal(stream) {
      this.isShowModalVideo = true
      this.selectedVideo = stream
      this.$emit('show-modal-video', stream)
    }
  },

  async mounted() {
    const id = { units: [{ id: this.item.id }] }
    const { data } = await videosApi.getVideos(id)
    this.streams = data
    setTimeout(() => {
      const hls = new Hls()
      this.hls1 = hls
      const stream = this.streams[0].url
      const video = document.getElementById(`videoPlayer0`)
      const loader = document.getElementById(`loading-indicator0`)
      loader.style.display = 'inline-block'
      hls.loadSource(stream)
      hls.attachMedia(video)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play()
        loader.style.display = 'none'
      })

      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          this.isError = true
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // В случае возникновения сетевой ошибки показать loader и заново запросить потом по url
              hls.stopLoad()
              setTimeout(() => {
                // Запустить воспроизведение HLS потока снова, используя тот же URL
                hls.loadSource(stream)
                this.isError = false
              }, 3000)
              break
            case Hls.ErrorTypes.MEDIA_ERROR:
              hls.recoverMediaError()
              break
            default:
              // Если ошибку нельзя исправить, вывести сообщение об ошибке
              console.error(
                `Error loading media: ${data.type}, details: ${data.details}`
              )
              break
          }
        }
      })
    }, 2000)

    setTimeout(() => {
      this.streams.slice(1).forEach((el, index) => {
        const hls = new Hls()
        this.hlsArr.push(hls)
        const stream = el.url
        const video = document.getElementById(`videoPlayer${index + 1}`)
        const loader = document.getElementById(`loading-indicator${index + 1}`)
        loader.style.display = 'inline-block'
        hls.loadSource(stream)
        hls.attachMedia(video)
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play()
          loader.style.display = 'none'
        })

        hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                hls.stopLoad()
                setTimeout(() => {
                  // Запустить воспроизведение HLS потока снова, используя тот же URL
                  hls.loadSource(stream)
                  this.isError = false
                }, 3000)
                break
              case Hls.ErrorTypes.MEDIA_ERROR:
                hls.recoverMediaError()
                break
              default:
                // Если ошибку нельзя исправить, вывести сообщение об ошибке
                console.error(
                  `Error loading media: ${data.type}, details: ${data.details}`
                )
                break
            }
          }
        })
      })
    }, 10000)
  },

  beforeDestroy() {
    this.hls1.destroy()
    this.hlsArr.forEach((el) => {
      el.destroy()
    })
  }
}
</script>

<style lang="stylus" scoped>
.cameras
  position absolute
  top 80px
  width 400px
  right 20px
  background #fff
  border-radius 4px
  z-index 9
  &__header {
    padding 17px 20px
    margin-bottom 12px

    .cameras-name {
      font-weight 600
      font-size 16px
      .name {
        font-weight 400
        font-size 16px
      }
    }
  }

  &__body {
    .body {
      &__switch-button {
        width 233px
        margin 0 auto
        margin-bottom 12px
        border 2px solid #DCE1E7
        border-radius 10px

        .switch-button {
          padding 2px 35px
          margin 4px
          font-weight 700
          font-size 13px
          line-height 19px
          outline none
          &--active {
            border-radius 6px
            background #EDEFF3
          }
        }
      }

      &__stream {
        display grid
        grid-template-columns 1fr 1fr
        gap 16px
        padding 17px
        pading-top 0
        .wrapper-video {
          position relative

          .video-error {
            position: absolute;
            top: 0;
            bottom: calc(100% - 97px);
            right: 0;
            left: 0;
            display flex
            align-items center
            justify-content center
            flex-direction column
            border-radius: 8px;
            background: #595959;
            .text {
              color #CDD4DD
            }
            &--tape {
              bottom: calc(100% - 203px);
            }
          }
        }
        .video {
          border 1px solid #F6F7FA
          border-radius 8px
          width 172px
          height 97px
          cursor pointer
          &--tape {
            width 360px
            height 203px
          }
        }

        .name-loader {
          display flex
          align-items center
          margin-top 10px
        }
        .name {
          display inline-block
          margin-right 24px
          font-weight 600
          font-size 16px
        }

        .btn-show {
          display none
          position absolute
          top 40%
          left 50%
          transform translate(-50%, -50%)
          background rgba(0, 0, 0, 0.8)
          z-index 1
          color white
          padding 11px 17px
          border-radius 20px
        }

        &--tape {
          grid-template-columns 1fr
          height 600px
          overflow-y scroll
        }
      }
    }
  }
  .header {
    border-bottom 1px solid #BEC7D3
    &__title {
      display flex
      justify-content space-between

      .title {
        font-weight 700
        font-size 24px
      }
    }
  }
</style>
